import APIService from "./api.service";

class DealTagOptionsService extends APIService<DealTagOptionsType> {
    constructor() {
        super("/deal-tag-options");
    }
}

export type DealTagOptionsType = {
    id?: number;
    name: string;
    fieldName: string;
};
export default new DealTagOptionsService();
