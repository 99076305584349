import APIService from "./api.service";

class ErcEntityService extends APIService<ErcEntity> {
    constructor() {
        super("/erc-entities");
    }
}

export type ErcEntity = {
    id?: number;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    phone?: string;
    email?: string;
    website?: string;
    defaultFolder?: number;
    ownedBy?: number;
    paidInFull?: 0 | 1;
    feePercentage?: number;
    dealPayStatus?: string;
    refundBeforeInterestQ2_2020?: number;
    refundBeforeInterestQ3_2020?: number;
    refundBeforeInterestQ4_2020?: number;
    refundBeforeInterestQ1_2021?: number;
    refundBeforeInterestQ2_2021?: number;
    refundBeforeInterestQ3_2021?: number;
    refundBeforeInterestQ4_2021?: number;
    receivedQ2_2020?: number;
    receivedQ3_2020?: number;
    receivedQ4_2020?: number;
    receivedQ1_2021?: number;
    receivedQ2_2021?: number;
    receivedQ3_2021?: number;
    receivedQ4_2021?: number;
    amountPaid?: number;
    filedQ3_2021?: 1 | 0;
    q3_2021Disallowance?: 0 | 1;
    taxAdvocacyFee?: number;
    taxAdvocacyFeePaid?: number;
};
export default new ErcEntityService();
