import APIService from "./api.service";

class DealTagService extends APIService<DealTagType> {
    constructor() {
        super("/deal-tags");
    }
}

export type DealTagType = {
    id?: number;
    dealId: number;
    tagId: number;
};
export default new DealTagService();
