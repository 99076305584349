import { useMutation } from "@tanstack/react-query";
import { makeRequest } from "../../jason-proof-of-concept/shared/utils";
import { useFinchConnect } from "@tryfinch/react-connect";

export const useFinch = ({
    authToken,
    companyId,
    onSuccess,
    onError,
    onClose,
}: {
    authToken: string;
    companyId?: number;
    onSuccess?: ({ data, connection }: { data: any; connection: any }) => void;
    onError?: (error: any) => void;
    onClose?: () => void;
}) => {
    const createLinkMutation = useMutation({
        mutationFn: async ({ code }: { code: string }) => {
            if (!companyId) {
                throw new Error("Company id not defined");
            }

            const connection = await makeRequest({
                method: "post",
                path: "/finch/link",
                authToken,
                data: { code, companyId },
            });

            return connection;
        },
    });

    const { open } = useFinchConnect({
        clientId: process.env.REACT_APP_FINCH_CLIENT_ID,
        sandbox: "finch",
        products: [
            "company",
            "directory",
            "individual",
            "ssn",
            "benefits",
            "payment",
            "pay_statement",
            "employment",
            "documents",
        ],
        onSuccess: async (data) => {
            const connection = await createLinkMutation.mutateAsync({ code: data.code });
            if (onSuccess) {
                onSuccess({ data, connection });
            }
        },
        onError,
        onClose,
    });

    return { open };
};
